// TODO use one login type
export enum ELoginType {
  KEEPER = 'keeper',
  EXCHANGE = 'exchange',
}

// TODO use one login type
export enum LoginType {
  SEED,
  EMAIL,
}
